import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import {
  INormalizedLmcDataType,
  amountCardFormProps,
  lmcOptionComponentProps,
} from "./types";
import LmcUtil from "./util";
import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import LMCButtons from "../../../public/LMCButtons";
import {
  checkKeydownDecimalValue,
  onInputCheckDecimal,
  onPasteCheckDecimal,
} from "../../../../utils/inputs";

export const currencyMapper: {
  [key: string]: {
    symbol: string;
    name: string;
    position: "before" | "after";
    type: "currency" | "crypto";
    dir?: "ltr" | "rtl";
  };
} = {
  USD: {
    symbol: "$",
    name: "US Dollar",
    position: "before",
    type: "currency",
  },
  CAD: {
    symbol: "$",
    name: "Canadian Dollar",
    position: "before",
    type: "currency",
  },
  EUR: {
    symbol: "€",
    name: "Euro",
    position: "before",
    type: "currency",
  },
  CHF: {
    symbol: "CHF",
    name: "Swiss Franc",
    position: "before",
    type: "currency",
  },
  AED: {
    symbol: "د.إ.",
    name: "United Arab Emirates Dirham",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  AFN: {
    symbol: "؋",
    name: "Afghan Afghani",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  ALL: {
    symbol: "Lek",
    name: "Albanian Lek",
    position: "after",
    type: "currency",
  },
  AMD: {
    symbol: "դր.",
    name: "Armenian Dram",
    position: "after",
    type: "currency",
  },
  ARS: {
    symbol: "$",
    name: "Argentine Peso",
    position: "before",
    type: "currency",
  },
  AUD: {
    symbol: "$",
    name: "Australian Dollar",
    position: "before",
    type: "currency",
  },
  AZN: {
    symbol: "ман.",
    name: "Azerbaijani Manat",
    position: "after",
    type: "currency",
  },
  BAM: {
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    position: "after",
    type: "currency",
  },
  BDT: {
    symbol: "৳",
    name: "Bangladeshi Taka",
    position: "before",
    type: "currency",
  },
  BGN: {
    symbol: "лв.",
    name: "Bulgarian Lev",
    position: "after",
    type: "currency",
  },
  BHD: {
    symbol: "د.ب.",
    name: "Bahraini Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  BIF: {
    symbol: "FBu",
    name: "Burundian Franc",
    position: "after",
    type: "currency",
  },
  BND: {
    symbol: "$",
    name: "Brunei Dollar",
    position: "before",
    type: "currency",
  },
  BOB: {
    symbol: "Bs",
    name: "Bolivian Boliviano",
    position: "before",
    type: "currency",
  },
  BRL: {
    symbol: "R$",
    name: "Brazilian Real",
    position: "before",
    type: "currency",
  },
  BWP: {
    symbol: "P",
    name: "Botswanan Pula",
    position: "before",
    type: "currency",
  },
  BYN: {
    symbol: "руб.",
    name: "Belarusian Ruble",
    position: "after",
    type: "currency",
  },
  BZD: {
    symbol: "$",
    name: "Belize Dollar",
    position: "before",
    type: "currency",
  },
  CDF: {
    symbol: "FrCD",
    name: "Congolese Franc",
    position: "after",
    type: "currency",
  },
  CLP: {
    symbol: "$",
    name: "Chilean Peso",
    position: "before",
    type: "currency",
  },
  CNY: {
    symbol: "CN¥",
    name: "Chinese Yuan",
    position: "before",
    type: "currency",
  },
  COP: {
    symbol: "$",
    name: "Colombian Peso",
    position: "before",
    type: "currency",
  },
  CRC: {
    symbol: "₡",
    name: "Costa Rican Colón",
    position: "before",
    type: "currency",
  },
  CVE: {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
    position: "before",
    type: "currency",
  },
  CZK: {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    position: "after",
    type: "currency",
  },
  DJF: {
    symbol: "Fdj",
    name: "Djiboutian Franc",
    position: "after",
    type: "currency",
  },
  DKK: {
    symbol: "kr",
    name: "Danish Krone",
    position: "after",
    type: "currency",
  },
  DOP: {
    symbol: "RD$",
    name: "Dominican Peso",
    position: "before",
    type: "currency",
  },
  DZD: {
    symbol: "د.ج.",
    name: "Algerian Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  EEK: {
    symbol: "kr",
    name: "Estonian Kroon",
    position: "after",
    type: "currency",
  },
  EGP: {
    symbol: "ج.م.",
    name: "Egyptian Pound",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  ERN: {
    symbol: "Nfk",
    name: "Eritrean Nakfa",
    position: "after",
    type: "currency",
  },
  ETB: {
    symbol: "Br",
    name: "Ethiopian Birr",
    position: "before",
    type: "currency",
  },
  GBP: {
    symbol: "£",
    name: "British Pound Sterling",
    position: "before",
    type: "currency",
  },
  GEL: {
    symbol: "GEL",
    name: "Georgian Lari",
    position: "after",
    type: "currency",
  },
  GHS: {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
    position: "before",
    type: "currency",
  },
  GNF: {
    symbol: "FG",
    name: "Guinean Franc",
    position: "after",
    type: "currency",
  },
  GTQ: {
    symbol: "Q",
    name: "Guatemalan Quetzal",
    position: "before",
    type: "currency",
  },
  HKD: {
    symbol: "$",
    name: "Hong Kong Dollar",
    position: "before",
    type: "currency",
  },
  HNL: {
    symbol: "L",
    name: "Honduran Lempira",
    position: "before",
    type: "currency",
  },
  HRK: {
    symbol: "kn",
    name: "Croatian Kuna",
    position: "after",
    type: "currency",
  },
  HUF: {
    symbol: "Ft",
    name: "Hungarian Forint",
    position: "after",
    type: "currency",
  },
  IDR: {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    position: "before",
    type: "currency",
  },
  ILS: {
    symbol: "₪",
    name: "Israeli New Sheqel",
    position: "before",
    type: "currency",
  },
  INR: {
    symbol: "টকা",
    name: "Indian Rupee",
    position: "after",
    type: "currency",
  },
  IQD: {
    symbol: "د.ع.",
    name: "Iraqi Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  IRR: {
    symbol: "﷼",
    name: "Iranian Rial",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  ISK: {
    symbol: "kr",
    name: "Icelandic Króna",
    position: "after",
    type: "currency",
  },
  JMD: {
    symbol: "$",
    name: "Jamaican Dollar",
    position: "before",
    type: "currency",
  },
  JOD: {
    symbol: "د.أ.",
    name: "Jordanian Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  JPY: {
    symbol: "￥",
    name: "Japanese Yen",
    position: "before",
    type: "currency",
  },
  KES: {
    symbol: "Ksh",
    name: "Kenyan Shilling",
    position: "before",
    type: "currency",
  },
  KHR: {
    symbol: "៛",
    name: "Cambodian Riel",
    position: "after",
    type: "currency",
  },
  KMF: {
    symbol: "FC",
    name: "Comorian Franc",
    position: "after",
    type: "currency",
  },
  KRW: {
    symbol: "₩",
    name: "South Korean Won",
    position: "before",
    type: "currency",
  },
  KWD: {
    symbol: "د.ك.",
    name: "Kuwaiti Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  KZT: {
    symbol: "тңг.",
    name: "Kazakhstani Tenge",
    position: "after",
    type: "currency",
  },
  LBP: {
    symbol: "ل.ل.",
    name: "Lebanese Pound",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  LKR: {
    symbol: "SL Re",
    name: "Sri Lankan Rupee",
    position: "before",
    type: "currency",
  },
  LTL: {
    symbol: "Lt",
    name: "Lithuanian Litas",
    position: "after",
    type: "currency",
  },
  LVL: {
    symbol: "Ls",
    name: "Latvian Lats",
    position: "before",
    type: "currency",
  },
  LYD: {
    symbol: "د.ل.",
    name: "Libyan Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  MAD: {
    symbol: "د.م.",
    name: "Moroccan Dirham",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  MDL: {
    symbol: "MDL",
    name: "Moldovan Leu",
    position: "after",
    type: "currency",
  },
  MGA: {
    symbol: "MGA",
    name: "Malagasy Ariary",
    position: "after",
    type: "currency",
  },
  MKD: {
    symbol: "MKD",
    name: "Macedonian Denar",
    position: "after",
    type: "currency",
  },
  MMK: {
    symbol: "K",
    name: "Myanma Kyat",
    position: "before",
    type: "currency",
  },
  MOP: {
    symbol: "MOP$",
    name: "Macanese Pataca",
    position: "before",
    type: "currency",
  },
  MUR: {
    symbol: "MURs",
    name: "Mauritian Rupee",
    position: "before",
    type: "currency",
  },
  MXN: {
    symbol: "$",
    name: "Mexican Peso",
    position: "before",
    type: "currency",
  },
  MYR: {
    symbol: "RM",
    name: "Malaysian Ringgit",
    position: "before",
    type: "currency",
  },
  MZN: {
    symbol: "MTn",
    name: "Mozambican Metical",
    position: "after",
    type: "currency",
  },
  NAD: {
    symbol: "N$",
    name: "Namibian Dollar",
    position: "before",
    type: "currency",
  },
  NGN: {
    symbol: "₦",
    name: "Nigerian Naira",
    position: "before",
    type: "currency",
  },
  NIO: {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
    position: "before",
    type: "currency",
  },
  NOK: {
    symbol: "kr",
    name: "Norwegian Krone",
    position: "after",
    type: "currency",
  },
  NPR: {
    symbol: "नेरू",
    name: "Nepalese Rupee",
    position: "before",
    type: "currency",
  },
  NZD: {
    symbol: "$",
    name: "New Zealand Dollar",
    position: "before",
    type: "currency",
  },
  OMR: {
    symbol: "ر.ع.",
    name: "Omani Rial",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  PAB: {
    symbol: "B/.",
    name: "Panamanian Balboa",
    position: "before",
    type: "currency",
  },
  PEN: {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
    position: "before",
    type: "currency",
  },
  PHP: {
    symbol: "₱",
    name: "Philippine Peso",
    position: "before",
    type: "currency",
  },
  PKR: {
    symbol: "₨",
    name: "Pakistani Rupee",
    position: "before",
    type: "currency",
  },
  PLN: {
    symbol: "zł",
    name: "Polish Zloty",
    position: "after",
    type: "currency",
  },
  PYG: {
    symbol: "₲",
    name: "Paraguayan Guarani",
    position: "after",
    type: "currency",
  },
  QAR: {
    symbol: "ر.ق.",
    name: "Qatari Rial",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  RON: {
    symbol: "RON",
    name: "Romanian Leu",
    position: "after",
    type: "currency",
  },
  RSD: {
    symbol: "дин.",
    name: "Serbian Dinar",
    position: "after",
    type: "currency",
  },
  RUB: {
    symbol: "₽.",
    name: "Russian Ruble",
    position: "after",
    type: "currency",
  },
  RWF: {
    symbol: "FR",
    name: "Rwandan Franc",
    position: "after",
    type: "currency",
  },
  SAR: {
    symbol: "ر.س.",
    name: "Saudi Riyal",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  SDG: {
    symbol: "SDG",
    name: "Sudanese Pound",
    position: "after",
    type: "currency",
  },
  SEK: {
    symbol: "kr",
    name: "Swedish Krona",
    position: "after",
    type: "currency",
  },
  SGD: {
    symbol: "$",
    name: "Singapore Dollar",
    position: "before",
    type: "currency",
  },
  SOS: {
    symbol: "Ssh",
    name: "Somali Shilling",
    position: "before",
    type: "currency",
  },
  SYP: {
    symbol: "ل.س.",
    name: "Syrian Pound",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  THB: {
    symbol: "฿",
    name: "Thai Baht",
    position: "before",
    type: "currency",
  },
  TND: {
    symbol: "د.ت.",
    name: "Tunisian Dinar",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  TOP: {
    symbol: "T$",
    name: "Tongan Paʻanga",
    position: "before",
    type: "currency",
  },
  TRY: {
    symbol: "TL",
    name: "Turkish Lira",
    position: "after",
    type: "currency",
  },
  TTD: {
    symbol: "$",
    name: "Trinidad and Tobago Dollar",
    position: "before",
    type: "currency",
  },
  TWD: {
    symbol: "NT$",
    name: "New Taiwan Dollar",
    position: "before",
    type: "currency",
  },
  TZS: {
    symbol: "TSh",
    name: "Tanzanian Shilling",
    position: "before",
    type: "currency",
  },
  UAH: {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    position: "after",
    type: "currency",
  },
  UGX: {
    symbol: "USh",
    name: "Ugandan Shilling",
    position: "before",
    type: "currency",
  },
  UYU: {
    symbol: "$",
    name: "Uruguayan Peso",
    position: "before",
    type: "currency",
  },
  UZS: {
    symbol: "UZS",
    name: "Uzbekistan Som",
    position: "after",
    type: "currency",
  },
  VEF: {
    symbol: "Bs.F.",
    name: "Venezuelan Bolívar",
    position: "before",
    type: "currency",
  },
  VND: {
    symbol: "₫",
    name: "Vietnamese Dong",
    position: "after",
    type: "currency",
  },
  XAF: {
    symbol: "FCFA",
    name: "CFA Franc BEAC",
    position: "after",
    type: "currency",
  },
  XOF: {
    symbol: "CFA",
    name: "CFA Franc BCEAO",
    position: "after",
    type: "currency",
  },
  YER: {
    symbol: "ر.ي.",
    name: "Yemeni Rial",
    position: "after",
    type: "currency",
    dir: "rtl",
  },
  ZAR: {
    symbol: "R",
    name: "South African Rand",
    position: "before",
    type: "currency",
  },
  ZMK: {
    symbol: "ZK",
    name: "Zambian Kwacha",
    position: "before",
    type: "currency",
  },
  ZWL: {
    symbol: "ZWL$",
    name: "Zimbabwean Dollar",
    position: "before",
    type: "currency",
  },
  BTC: {
    symbol: "₿",
    name: "Bitcoin",
    position: "before",
    type: "crypto",
  },
  ETH: {
    symbol: "Ξ",
    name: "Ethereum",
    position: "before",
    type: "crypto",
  },
  USDT: {
    symbol: "₮",
    name: "Tether",
    position: "before",
    type: "crypto",
  },
  BNB: {
    symbol: "BNB",
    name: "Binance Coin",
    position: "before",
    type: "crypto",
  },
  XRP: { symbol: "XRP", name: "Ripple", position: "before", type: "crypto" },
  SOL: { symbol: "SOL", name: "Solana", position: "before", type: "crypto" },
  ADA: { symbol: "₳", name: "Cardano", position: "before", type: "crypto" },
  DOGE: { symbol: "Ð", name: "Dogecoin", position: "before", type: "crypto" },
  AVAX: {
    symbol: "AVAX",
    name: "Avalanche",
    position: "before",
    type: "crypto",
  },
  SHIB: {
    symbol: "SHIB",
    name: "Shiba Inu",
    position: "before",
    type: "crypto",
  },
  DOT: { symbol: "DOT", name: "Polkadot", position: "before", type: "crypto" },
  LTC: { symbol: "Ł", name: "Litecoin", position: "before", type: "crypto" },
  XMR: { symbol: "ɱ", name: "Monero", position: "before", type: "crypto" },
  LINK: {
    symbol: "LINK",
    name: "Chainlink",
    position: "before",
    type: "crypto",
  },
  BCH: {
    symbol: "₿CH",
    name: "Bitcoin Cash",
    position: "before",
    type: "crypto",
  },
  MATIC: {
    symbol: "MATIC",
    name: "Polygon",
    position: "before",
    type: "crypto",
  },
  XLM: { symbol: "XLM", name: "Stellar", position: "before", type: "crypto" },
  ALGO: {
    symbol: "ALGO",
    name: "Algorand",
    position: "before",
    type: "crypto",
  },
  ATOM: { symbol: "ATOM", name: "Cosmos", position: "before", type: "crypto" },
  FIL: { symbol: "FIL", name: "Filecoin", position: "before", type: "crypto" },
};

const uniqueValues = (arr: Array<any>, key: string) => {
  const seen = new Set();
  for (const obj of arr) {
    const value = obj[key];
    if (value !== null && value !== "" && seen.has(value)) {
      return false;
    }
    seen.add(value);
  }
  return true;
};

const validatePrecision = (value: number, precision: number) => {
  if (typeof value !== "number") {
    throw new Error("Input value must be a number");
  }

  const stringValue = value.toString();
  const decimalIndex = stringValue.indexOf(".");

  if (decimalIndex === -1) {
    return true; // No decimal places
  }

  const decimalPlaces = stringValue.length - decimalIndex - 1;
  return decimalPlaces <= precision;
};

const atLeastOneItemIsFilled = (arr: Array<{ value: number }>) => {
  let hasOne = arr.find(
    (item) =>
      Object.keys(item).length > 0 && item.value > 0 && item.value !== null
  );

  return hasOne ? true : false;
};

const schema = Joi.object()
  .keys({
    values: Joi.array()
      .items(
        Joi.object({
          value: Joi.number()
            .custom((value, helper) => {
              if (!validatePrecision(value, 5)) {
                // Define the error message using helper.message()
                return helper.message(
                  "Number must have at most 5 decimal places" as any
                );
              }
              return value;
            })
            .positive()
            .min(0.00001)
            .max(9999999999)
            // .required()
            .allow(null)
            .allow("")
            .optional()
            .label("Default amount"),
        })
      )
      .min(1)
      .max(3)
      .required()
      .custom((value, helper) => {
        if (!uniqueValues(value, "value")) {
          return helper.message(
            "Default amounts must contain unique values for default amount" as any
          );
        }
        return value;
      })
      .custom((value, helper) => {
        if (!atLeastOneItemIsFilled(value)) {
          return helper.message(
            "Default amounts must contain at least one value" as any
          );
        }
        return value;
      })
      .label("Default amounts")
      .messages({
        "array.includesRequiredKnowns":
          "Add at least one value to the quick access buttons",
      }),
    defaultValue: Joi.string().optional().label("Default value"),
    currency: Joi.string().required().label("Currency"),
    customValue: Joi.boolean().required().label("Custom value"),
    comment: Joi.string().max(100).optional().allow("", null).label("Comment"),
  })
  .required();

const AmountCard = ({
  label,
  optionData,
  onSubmitCardData,
  playgroundDataHandler,
  handleCancelEdit,
  tutorial,
}: lmcOptionComponentProps) => {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    trigger,
  } = useForm<amountCardFormProps>({
    resolver: joiResolver(schema),
    // mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "values", // unique name for your Field Array
  });

  useEffect(() => {
    if (optionData) {
      let denormalizedData = LmcUtil.unwrapper(
        "AMOUNT_CARD",
        optionData
      ) as amountCardFormProps;

      reset({ ...denormalizedData });
    }
  }, [optionData, reset]);

  const onSubmit = (data: amountCardFormProps) => {
    const newData = {
      ...data,
      values: data.values
        .filter((item: any) => item.value !== null)
        .filter((item: any) => item.value !== "")
        .map((v) => v.value),
    };

    const normalizedData = LmcUtil.wrapper(
      "AMOUNT_CARD",
      newData
    ) as INormalizedLmcDataType;

    onSubmitCardData("AMOUNT_CARD", normalizedData).then(() => {
      reset();
    });
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      const newData = {
        ...value,
        values: value?.values
          ?.filter((item: any) => item.value !== null)
          ?.filter((item: any) => item.value !== "")
          ?.map((v) => v?.value),
      };

      playgroundDataHandler({
        type: "AMOUNT_CARD",
        data: LmcUtil.wrapper("AMOUNT_CARD", newData) as INormalizedLmcDataType,
      });
    });
  }, [playgroundDataHandler, watch]);

  return (
    <div className="lmc-card point-card w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <Label
            htmlFor={"defaultValue"}
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.amountCard.label3")}
          </Label>
          <Select
            color={!!errors.currency ? "failure" : ""}
            aria-label="Default select example"
            helperText={<>{errors.currency?.message}</>}
            {...register("currency")}
          >
            <option value="">
              {t("dashboard.features.lmc.amountCard.placeholder3")}
            </option>
            {Object.entries(currencyMapper).map(([code, { symbol, name }]) => (
              <option key={code} value={code}>
                {`${code} - ${name} (${symbol})`}
              </option>
            ))}
          </Select>
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        {fields.map((item, index) => (
          <div className="mb-3" key={item.id}>
            <Label className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none">
              {t("dashboard.features.lmc.amountCard.label1") as string}
            </Label>
            <div className="grid grid-cols-7">
              <div
                className={`${fields.length > 1 ? "col-span-6" : "col-span-7"}`}
              >
                <TextInput
                  helperText={<>{errors.values?.[index]?.value?.message}</>}
                  color={!!errors.values?.[index] ? "failure" : ""}
                  //   type="number"
                  inputMode="decimal"
                  onInput={(e) => onInputCheckDecimal(e)}
                  onKeyDown={(e) => checkKeydownDecimalValue(e)}
                  onPaste={(e) => onPasteCheckDecimal(e)}
                  placeholder={
                    t("dashboard.features.lmc.pointCard.placeholder1") as string
                  }
                  {...register(`values.${index}.value`, {
                    onChange(event) {
                      trigger();
                    },
                  })}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </div>
              {fields.length > 1 && (
                <div className="col-span-1">
                  <Button
                    color={""}
                    className="text-gray-300"
                    onClick={() => remove(index)}
                  >
                    <HiTrash size={22} />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}

        {watch("values")?.length < 3 && (
          <Button
            type="button"
            color="light"
            onClick={() => append({ value: null })}
            className="mb-3 w-full"
          >
            {t("dashboard.features.lmc.amountCard.key1")}
          </Button>
        )}

        {!!errors.values && (
          <span className="text-sm text-red-600 block mb-3">
            {errors.values?.message?.toString()}
          </span>
        )}

        <div className="mb-3">
          <div className="flex items-center gap-2">
            <label className="switch shrink-0">
              <input type="checkbox" {...register("customValue")} />
              <div className="slider"></div>
            </label>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.features.lmc.amountCard.label5")}
            </span>
          </div>
        </div>

        <div className="w-full h-px bg-gray-300 mb-3" />

        <div className="mb-3">
          <Label
            htmlFor={"comment"}
            className="block mb-3 text-zinc-500 text-xs font-normal font-['Figtree'] leading-none"
          >
            {t("dashboard.features.lmc.amountCard.label4")}
          </Label>
          <Textarea
            color={!!errors.comment ? "failure" : ""}
            rows={3}
            placeholder={
              t("dashboard.features.lmc.amountCard.placeholder4") as string
            }
            helperText={<>{errors.comment?.message}</>}
            {...register("comment")}
          />
        </div>

        <LMCButtons tutorial={tutorial} handleCancelEdit={handleCancelEdit} />
      </form>
    </div>
  );
};

export const AmountCardUi = ({
  data,
}: {
  data: INormalizedLmcDataType | null;
}) => {
  const { t } = useTranslation();
  const currency = data?.pre_defined_value?.currency;
  const mapper = currencyMapper[data?.pre_defined_value?.currency];
  return (
    <div className="self-stretch  flex-col justify-start items-center gap-6 flex">
      <h4 className="text-2xl font-bold text-gray-800">
        {t("dashboard.features.loginTemplate.tempPart.btn-01")}
      </h4>

      <div className="self-stretch  bg-white rounded-2xl shadow flex-col justify-start items-center gap-6 flex">
        <div className="self-stretch px-4 pb-5 flex-col justify-center items-center gap-3 flex pt-4">
          <div className="text-center text-zinc-500 text-base font-bold font-['Figtree'] leading-normal">
            {t("dashboard.features.lmc.amountCard.key8")}
          </div>
          {data?.options?.custom_value && (
            <div className="self-stretch h-11 flex-col justify-center items-center gap-4 flex">
              <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                  <div className="text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal">
                    {t("dashboard.features.lmc.amountCard.key9")}
                  </div>
                </div>
              </div>
            </div>
          )}
          {data?.pre_defined_value?.values?.map((v, index) => {
            if (v > 0 && v <= 9999999999)
              return (
                <div
                  key={index}
                  className="self-stretch h-11 flex-col justify-center items-center gap-4 flex"
                >
                  <div className="self-stretch h-11 bg-gray-200 rounded-[99px] flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
                      <div
                        className={`text-center text-gray-700 text-base font-semibold font-['Figtree'] leading-normal ${
                          currency && mapper?.dir === "rtl" && "direction-rtl"
                        }`}
                      >
                        {currency &&
                          mapper?.position === "before" &&
                          (mapper?.type === "currency"
                            ? mapper?.symbol
                            : currency)}{" "}
                        {new Intl.NumberFormat().format(v)}{" "}
                        {currency &&
                          mapper?.position === "after" &&
                          (mapper?.type === "currency"
                            ? mapper?.symbol
                            : currency)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            else return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default AmountCard;
