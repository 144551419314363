import moment from "moment";
import { useLmcUserView } from "../../hooks/useLmcUserView";

const LmcRewardMessage = ({
  messages = [],
}: {
  messages: { date: string; value: string }[] | [];
}) => {
  const {
    state: { entity },
  } = useLmcUserView();

  return (
    <div className="w-full py-4">
      {messages?.map((item, index) => {
        if (entity.process_data?.options.nb_history_message > index)
          return (
            <div key={index} className="p-4 border-b border-gray-800">
              <h4 className="text-gray-200 text-lg font-semibold font-['Figtree'] leading-7">
                {item.value}
              </h4>
              <p className="text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                {moment(item.date).format("MMM Do, YYYY")}
              </p>
            </div>
          );
        else return null;
      })}
    </div>
  );
};

export default LmcRewardMessage;
