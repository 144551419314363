import { joiResolver } from "@hookform/resolvers/joi";
import { AxiosError } from "axios";
import {
  Button,
  Label,
  Modal,
  Textarea,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import * as Joi from "joi";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BiChevronDown,
  BiChevronUp,
  BiPaperclip,
  BiShow,
  BiSmile,
  BiTrash,
} from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import HttpService from "../../../../services/http";
import { ACCEPTED_FILE_TYPES } from "../../../../constants/fileTypes";
import {
  useSendMessagesToAll,
  useSendMessagesToUsers,
  useSendMessageToOne,
} from "../../../../hooks/messages";
import EmojiPicker from "emoji-picker-react";
import { SEND_MESSAGE_TYPE } from "../../../../constants/types";
import moment from "moment-timezone";
import TimeZoneSelector from "./TimeZoneSelector";
import useAuth from "../../../../hooks/useAuth";

type propTypes = {
  show: boolean;
  ctrFn: Dispatch<SetStateAction<boolean>>;
  toSendMsgUserId: string;
  entity_identification: string;
  entity_user_identification: string;
  userName: string;
  group_identification?: string;
  users?: Set<string>;
  onResolved?: () => void;
  type?: SEND_MESSAGE_TYPE;
};

type sendMsgFormType = {
  template?: string;
  subject: string;
  content: string;
  attachments?: FileList; // Add a new field for file attachments
  message_planned_date_time?: string | null;
  sendAnotherTime?: boolean;
};

const options = [
  {
    value: "old",
    label: "Default",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/2502031554528_email_temp_default-min.png",
  },
  {
    value: "white",
    label: "White",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020312585997_email_temp_white-min.png",
  },
  {
    value: "elegant",
    label: "Elegant",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020315545150_email_temp_elegant-min.png",
  },
  {
    value: "default",
    label: "Cyan",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/2502031258594_email_temp_blue-min.png",
  },
  {
    value: "dark",
    label: "Dark",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020312585854_email_temp_dark-min.png",
  },
  {
    value: "winter",
    label: "Winter",
    icon: "https://cdn.1cdn.app/application/LOUISEBOT/25020315545118_email_temp_winter-min.png",
  },
];

const Dropdown = ({
  name,
  onChange,
  value,
}: ControllerRenderProps<sendMsgFormType, "template">) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionClick = (option: any) => {
    onChange(option);
  };

  return (
    <div className="relative">
      <div
        className="flex items-center justify-between px-4 py-2 border border-gray-300 rounded-md cursor-pointer"
        onClick={() => setShowOptions(!showOptions)}
      >
        {options.find((opt) => opt.value === value)?.label || "Default"}
        {showOptions ? <BiChevronUp size={20} /> : <BiChevronDown size={20} />}
      </div>
      <div
        className={`absolute top-full left-0 mt-2 w-full rounded-md shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.06)] shadow-md border border-gray-300 bg-white z-10 ${
          showOptions ? "block" : "hidden"
        }`}
      >
        <ul className="p-2">
          {options.map((option) => (
            <li
              key={option.value}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
              onClick={() => {
                handleOptionClick(option.value);
                setShowOptions(false);
              }}
            >
              <span>{option.label}</span>
              {option.icon && (
                <img
                  src={option.icon}
                  alt={option.label}
                  className="w-6 h-6 mr-2"
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SendPersonalMessage = ({
  show,
  ctrFn,
  entity_user_identification,
  toSendMsgUserId,
  entity_identification,
  userName,
  group_identification,
  users,
  onResolved,
  type = SEND_MESSAGE_TYPE.SINGLE,
}: propTypes) => {
  const { t } = useTranslation();
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const attachmentInputRef = useRef<HTMLInputElement | null>(null);
  const [htmlContent, setHtmlContent] = useState("");
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]); // State to store uploaded files
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const { customer_identification, user } = useAuth();
  const contentInputRef = useRef<HTMLTextAreaElement>(null);

  const entityPreferencesQuery = useQuery(
    ["entity_preferences", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity/preferences`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const entityQuery = useQuery(
    ["entities", user.entityIdentification],
    () =>
      HttpService.get(
        `/${customer_identification}/${user.entityIdentification}/entity`,
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
      enabled: entityPreferencesQuery.isSuccess,
    }
  );

  const schema = Joi.object()
    .keys({
      template: Joi.string().default("old").optional(),
      subject: Joi.string()
        .max(100)
        .optional()
        .allow(null, "")
        .label(t("dashboard.entities.viewEntityUser.sendMsg.inp1Label")),
      content: Joi.string()
        .min(5)
        .max(3000)
        .required()
        .label(t("dashboard.entities.viewEntityUser.sendMsg.inp2Label")),
      sendAnotherTime: Joi.boolean(),
      message_planned_date_time: Joi.alternatives().conditional(
        "sendAnotherTime",
        {
          is: true,
          then: Joi.date().required().messages({
            "date.base": "Invalid date",
            "any.required": "Date and time are required",
          }),
          otherwise: Joi.any().optional(),
        }
      ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    control,
    setValue,
  } = useForm<sendMsgFormType>({
    resolver: joiResolver(schema),
    defaultValues: {
      template: "old",
      sendAnotherTime: false,
      message_planned_date_time: null,
    },
  });

  const { mutate: sendToAll, isLoading: isSendingToAll } =
    useSendMessagesToAll();
  const { mutate: sendToUsers, isLoading: isSendingToUsers } =
    useSendMessagesToUsers();
  const { mutate: sendToOne, isLoading: isSendingToOne } =
    useSendMessageToOne();

  const getTemplate = () => {
    let template = "sum_user_message";

    switch (watch().template) {
      case "old":
        template = "sum_user_message";
        break;
      case "white":
        template = "sum_user_message_white";
        break;
      case "elegant":
        template = "sum_user_message_elegant";
        break;
      case "default":
        template = "sum_user_message_default";
        break;
      case "dark":
        template = "sum_user_message_dark";
        break;
      case "winter":
        template = "sum_user_message_winter";
        break;
      default:
        template = "sum_user_message";
    }

    if (type !== SEND_MESSAGE_TYPE.SINGLE) template = template + "_bulk";
    return template;
  };

  const requestEmailPreview = useMutation<any, AxiosError<any, any>, any>(
    (data: any) =>
      HttpService.post(
        `/${entity_user_identification}/${entity_identification}/entity/template/${getTemplate()}/preview`,
        {
          projectLogo: data.projectLogo,
          projectName: data.projectName,
          title: data.subject,
          userName: data.userName,
          messageText: data.content,
        },
        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onSuccess: (response) => {
        setHtmlContent(response.data);
        setShowEmailPreview(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const handleRequestEmailView = () => {
    if (isValid) {
      const { content, subject } = watch();
      requestEmailPreview.mutate({
        projectLogo:
          entityPreferencesQuery.data?.data?.element?.cdn_logo_project_path,
        projectName: entityQuery?.data?.data?.element?.entity_name,
        subject,
        userName,
        content,
      });
    }
  };

  const onSubmit = async (data: sendMsgFormType) => {
    const formData = new FormData();

    formData.set("subject", data.subject);
    formData.set("content", data.content);
    if (data.message_planned_date_time) {
      const convertedDate = moment(data.message_planned_date_time)
        .tz(timezone, true)
        .toDate();

      formData.set("message_planned_date_time", convertedDate.toUTCString());
    }
    attachments.forEach((file) => {
      formData.append("files", file);
    });
    formData.set("template", getTemplate());

    if (type == SEND_MESSAGE_TYPE.SINGLE) {
      sendToOne(
        {
          customer_identification: entity_user_identification,
          entity_identification: entity_identification,
          toSendMsgUserId: toSendMsgUserId,
          data: formData,
        },
        {
          onSuccess: () => {
            onSuccessSubmit();
          },
        }
      );
    } else {
      if (type == SEND_MESSAGE_TYPE.MULTI) {
        formData.set("users", JSON.stringify(users?.values().toArray()));
      }
      const obj = {
        customer_identification: entity_user_identification,
        entity_identification: entity_identification,
        group_identification: group_identification,
        data: formData,
      };
      if (type == SEND_MESSAGE_TYPE.MULTI) {
        sendToUsers(obj, {
          onSuccess: () => {
            onSuccessSubmit();
            onResolved?.();
          },
        });
      } else {
        sendToAll(obj, {
          onSuccess: () => {
            onSuccessSubmit();
          },
        });
      }
    }
  };
  const onSuccessSubmit = () => {
    reset();
    ctrFn(false);
    setAttachments([]);
  };

  const handleTrigerFileSelection = () => {
    if (attachmentInputRef.current) {
      attachmentInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      if (selectedFiles.length + attachments.length <= 5) {
        setAttachments((prevFiles) => [...prevFiles, ...selectedFiles]);
      } else {
        toast.error(
          t("dashboard.entities.viewEntityUser.sendMsg.maxUploadFiles")
        );
      }
    }
  };

  const removeFile = (index: number) => {
    setAttachments((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current?.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const handleEmojiClick = (emojiObject: any) => {
    const content = watch("content");
    const textareaElement = contentInputRef.current;

    if (!textareaElement) {
      // Fallback if ref isn't available
      setValue("content", (content || "") + emojiObject.emoji);
      return;
    }

    const start = textareaElement.selectionStart ?? 0;
    const newText = content
        ? content.slice(0, start) + emojiObject.emoji + content.slice(start)
        : emojiObject.emoji;

    setValue("content", newText);

    // Set cursor position after the inserted emoji
    setTimeout(() => {
      textareaElement.focus();
      const newPosition = start + emojiObject.emoji.length;
      textareaElement.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onClose={() => {
          reset();
          ctrFn(false);
        }}
        className="myModal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="border-0 p-4">
            {t("dashboard.entities.viewEntityUser.sendMsg.modal-title")}
          </Modal.Header>
          <Modal.Body className="p-4">
            {/* Email template */}
            <div className="mb-3">
              <Label
                value={t(
                  "dashboard.entities.viewEntityUser.sendMsg.templateLabel"
                ).toString()}
                className="block mb-3"
              />
              <Controller
                control={control}
                name="template"
                render={({ field }) => <Dropdown {...field} />}
              />
            </div>

            {/* Subject */}
            <div className="mb-3 relative">
              <Label
                value={t(
                  "dashboard.entities.viewEntityUser.sendMsg.inp1Label"
                ).toString()}
                className="block mb-3"
              />

              <TextInput
                color={!!errors.subject ? "failure" : ""}
                type="text"
                placeholder={
                  t(
                    "dashboard.entities.viewEntityUser.sendMsg.inp1Placeholder"
                  ) as string
                }
                helperText={<>{errors?.subject?.message}</>}
                {...register("subject")}
              />
            </div>

            {/* Description */}
            <div className="mb-3 relative">
              <Label
                value={t(
                  "dashboard.entities.viewEntityUser.sendMsg.inp2Label"
                ).toString()}
                className="block mb-3"
              />

              <div
                className={`border border-solid border-gray-300 rounded-lg mb-3 ${
                  !!errors.content && "bg-red-50"
                }`}
              >
                <Textarea
                  color={!!errors.content ? "failure" : ""}
                  rows={7}
                  className="flex border-none focus:shadow-none focus:ring-0 resize-none py-2 bg-transparent"
                  placeholder={
                    t(
                      "dashboard.entities.viewEntityUser.sendMsg.inp2Placeholder"
                    ) as string
                  }
                  {...register("content")}
                  ref={(el) => {
                    // This special ref handles both react-hook-form and our own ref needs
                    const { ref } = register("content");
                    if (typeof ref === 'function') {
                      ref(el);
                    }
                    contentInputRef.current = el;
                  }}
                />

                <div
                  className={`flex  items-center justify-between px-4 mt-1 mb-2 ${
                    !!errors.content ? "bg-red-50" : "bg-white"
                  }`}
                >
                  <div className="flex items-center justify-start gap-2">
                    {/* <button
                    onClick={() => {}}
                    className="h-8 p-1 bg-white rounded-md shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] border border-gray-300 justify-start items-center gap-2.5 inline-flex"
                    type="button"
                  >
                    <BiFontColor size={20} />
                  </button> */}
                    <button
                      onClick={handleTrigerFileSelection}
                      className="h-8 p-1 bg-white rounded-md shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] border border-gray-300 justify-start items-center gap-2.5 inline-flex"
                      type="button"
                    >
                      <BiPaperclip size={20} />
                    </button>

                    <div className="relative hidden md:flex">
                      <button
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        className="h-8 p-1 bg-white rounded-md shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] border border-gray-300"
                        type="button"
                      >
                        <BiSmile size={20} />
                      </button>

                      {/* Emoji Picker */}
                      {showEmojiPicker && (
                        <div
                          ref={emojiPickerRef}
                          className="absolute bottom-[380px] left-0 z-10"
                        >
                          <EmojiPicker
                            onEmojiClick={handleEmojiClick}
                            height={"380px"}
                            allowExpandReactions={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={handleRequestEmailView}
                    disabled={!isValid || requestEmailPreview.isLoading}
                    className="h-8 pl-2 pr-3 py-1 bg-white disabled:bg-gray-200 rounded-md shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06)] border border-gray-300 justify-start items-center gap-1 inline-flex"
                  >
                    <BiShow size={20} />
                    <span className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">
                      {t("dashboard.entities.viewEntityUser.sendMsg.preview")}
                    </span>
                  </button>
                </div>
              </div>

              {errors?.content?.message ? (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  {errors?.content?.message}
                </p>
              ) : (
                <div className="text-end text-[#687076] text-xs font-normal font-['Figtree'] leading-none">
                  {t("dashboard.entities.viewEntityUser.sendMsg.inp2text")}
                </div>
              )}
            </div>

            {/* Attachments */}
            <div className="mb-3">
              <input
                ref={attachmentInputRef}
                type="file"
                multiple
                accept={ACCEPTED_FILE_TYPES}
                onChange={handleFileChange}
                hidden
              />
              {attachments.length > 0 && (
                <ul className="flex items-center flex-wrap gap-4">
                  {attachments.map((file, index) => (
                    <li
                      key={index}
                      className="flex w-auto w-full md:w-auto items-center border border-dashed border-gray-200 p-1 pr-4 text-sm"
                    >
                      <Button
                        size={"sm"}
                        color={""}
                        type="button"
                        onClick={() => removeFile(index)}
                        className="p-0"
                      >
                        <BiTrash color="red" />
                      </Button>
                      <span className="truncate">{file.name} </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="h-px bg-gray-300 my-6" />

            <div>
              <div className="flex max-w-md flex-col items-start gap-4 mb-4">
                <Controller
                  name="sendAnotherTime"
                  control={control}
                  render={({ field }) => (
                    <ToggleSwitch
                      label={
                        t(
                          "dashboard.entities.viewEntityUser.sendMsg.sendAnotherTime"
                        ) as string
                      }
                      className="customToggle"
                      checked={field.value || false}
                      onChange={(checked) => field.onChange(checked)}
                    />
                  )}
                />
              </div>
            </div>

            {watch("sendAnotherTime") && (
              <div className="block md:flex gap-5 justify-between mb-10">
                <div>
                  <Label
                    value={
                      t(
                        "dashboard.entities.viewEntityUser.sendMsg.timeZone"
                      ) as string
                    }
                    className="block mb-3"
                  />

                  <TimeZoneSelector
                    currentTimeZone={timezone}
                    onChange={(timezone) => setTimezone(timezone)}
                  />
                </div>
                <div className="">
                  <Label
                    value={
                      t(
                        "dashboard.entities.viewEntityUser.sendMsg.dateAndTime"
                      ) as string
                    }
                    className="block mb-4"
                  />

                  <Controller
                    name="message_planned_date_time"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        className="w-64  rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        type="datetime-local"
                        id="message_planned_date_time"
                        placeholder={
                          t(
                            "dashboard.entities.viewEntityUser.sendMsg.selectDateAndTime"
                          ) as string
                        }
                        color={
                          errors.message_planned_date_time ? "failure" : "gray"
                        }
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                  {errors.message_planned_date_time && (
                    <p className="text-red-500 text-sm">
                      {errors.message_planned_date_time.message}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-end bg-gray-50">
            <Button
              type="button"
              onClick={() => {
                reset();
                ctrFn(false);
                setAttachments([]);
              }}
              color={"light"}
            >
              {t("dashboard.entities.viewEntityUser.sendMsg.cancel")}
            </Button>

            <Button
              type="submit"
              className="bg-sky-500"
              isProcessing={isSendingToAll || isSendingToUsers}
              disabled={isSendingToAll || isSendingToUsers}
            >
              {t("dashboard.entities.viewEntityUser.sendMsg.submitBtn")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Modal preview email */}
      <Modal
        dismissible
        show={showEmailPreview}
        onClose={() => setShowEmailPreview(false)}
        size={"5xl"}
      >
        <Modal.Header>
          {t("dashboard.entities.viewEntityUser.sendMsg.key2")}
        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            className="h-full w-full"
            dangerouslySetInnerHTML={{
              __html: htmlContent,
            }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SendPersonalMessage;
