import { useLmcUserView } from "../../hooks/useLmcUserView";
import { useTranslation } from "react-i18next";
import { currencyMapper } from "../../../dashboard/lmc/AmountCard";

const LmcRewardCurrency = ({ amount = 0 }: { amount?: number }) => {
  const { t } = useTranslation();

  const {
    state: { entity },
  } = useLmcUserView();

  const currency = entity.process_data?.pre_defined_value?.currency;
  const mapper = currencyMapper[currency];

  return (
    <div className="w-full py-20 px-4 flex flex-col justify-start items-center gap-4">
      <h4 className="w-[158px] text-center text-gray-50 text-xl font-semibold font-['Figtree'] leading-7">
        {t("lmcUser.key40")}
      </h4>
      <h2
        className={`text-center text-emerald-300 text-6xl font-extrabold font-['Figtree'] leading-[60px] ${
          currency && mapper?.dir === "rtl" && "direction-rtl"
        }`}
      >
        {currency &&
          mapper?.position === "before" &&
          (mapper?.type === "currency" ? mapper?.symbol : currency)}{" "}
        {amount}{" "}
        {currency &&
          mapper?.position === "after" &&
          (mapper?.type === "currency" ? mapper?.symbol : currency)}
      </h2>
    </div>
  );
};

export default LmcRewardCurrency;
