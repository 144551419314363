import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import useAuth from "../../../../hooks/useAuth";
import HttpService from "../../../../services/http";
import { toast } from "react-toastify";
import moment from "moment";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { RoleOptionsPart } from "./RoleOptionsPart";
import { TypeOptionsPart } from "./TypeOptionPart";
import { Button, Checkbox, Table, Tooltip } from "flowbite-react";
import { FaArrowRight, FaCopy } from "react-icons/fa6";
import { AiOutlineLoading } from "react-icons/ai";
import { USER_LIST_COMPONENT_TYPE } from "../../../../constants/types";
import Pagination from "../../../public/Pagination";

export type User = {
  id: number;
  user_identification: string;
  user_email: string;
  user_is_active: boolean;
  user_role: string;
  user_type: string;
  user_creation_date_time: string;
  user_account_status: string;
};

type UsersListComponentProps = {
  users: Array<User>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  refetch: () => void;
  limit?: number;
  selectedRowIds: Set<string>;
  setSelectedRowIds: Dispatch<SetStateAction<Set<string>>>;
  type?: USER_LIST_COMPONENT_TYPE;
  pagination?: any;
  offset: number;
  setOffset: (value: number) => void;
};
const UsersListComponent = ({
  users,
  isLoading,
  isError,
  isSuccess,
  refetch,
  limit = 50,
  selectedRowIds,
  setSelectedRowIds,
  type = USER_LIST_COMPONENT_TYPE.USERS,
  pagination,
  offset = 0,
  setOffset,
}: UsersListComponentProps) => {
  const [t] = useTranslation();
  const [selectAll, setSelectAll] = useState<{
    isSelected: boolean;
    selectPage: number;
  }>({
    isSelected: false,
    selectPage: 0,
  });

  const { user, customer_identification, userEndedSubscription } = useAuth();
  const toggleRowSelection = (rowId: string) => {
    setSelectedRowIds((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(rowId)) {
        newSelected.delete(rowId);
      } else {
        newSelected.add(rowId);
      }
      return newSelected;
    });
  };
  const navigate = useNavigate();

  const handleCopy = (identification: string) => {
    navigator.clipboard.writeText(identification);

    toast.info("Identification copied!");
  };

  const {
    data: resRole,
    isLoading: isLoadingRole,
    isError: isErrorRole,
    isSuccess: isSuccessRole,
    refetch: refetchRole,
  } = useQuery(
    ["roles_list", user.userIdentification],
    () =>
      HttpService.get(
        `/${user.userIdentification}/${user.entityIdentification}/entity/roles`,

        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const {
    data: resType,
    isLoading: isLoadingType,
    isError: isErrorType,
    isSuccess: isSuccessType,
    refetch: refetchType,
  } = useQuery(
    ["types_list", user.userIdentification],
    () =>
      HttpService.get(
        `/${user.userIdentification}/${user.entityIdentification}/entity/types`,

        {
          auth: HttpService.getToken(),
        }
      ),
    {
      onError: (err: AxiosError<any, any>) => {
        toast.error(err.response?.data?.message || err.message);
      },
    }
  );

  const columns: ColumnDef<User>[] = [
    {
      header: () => (
        <Checkbox
          onChange={(e) => {
            const isChecked = e.target.checked;
            setSelectAll({
              isSelected: isChecked,
              selectPage: isChecked ? calculateCurrentPage() || 0 : 0,
            });

            setSelectedRowIds(
              isChecked
                ? new Set(
                    users?.map(
                      (item: any, index: number) => item?.user_identification
                    )
                  )
                : new Set()
            );
          }}
          checked={
            selectAll.isSelected &&
            selectAll.selectPage == (calculateCurrentPage() || 0)
          }
          className="appearance-none checked:bg-blue-600 focus:ring-none"
        />
      ),
      accessorKey: "id",
      cell: (props) => {
        const userIdentification = props.row.original.user_identification;
        return (
          <Checkbox
            onChange={() => toggleRowSelection(userIdentification)}
            checked={selectedRowIds.has(userIdentification)}
            id={userIdentification}
            className="appearance-none checked:bg-blue-600 focus:ring-none"
          />
        );
      },
      size: 10,
      enableSorting: false,
    },
    {
      accessorKey: "user_identification",
      header: () => t("dashboard.usersList.item-15"),
      cell(props) {
        const userIdentification = props.row.original.user_identification;
        return (
          <div className="flex items-center gap-2 group h-4">
            <span>
              {userIdentification?.substring(0, 4) +
                "..." +
                userIdentification?.slice(userIdentification.length - 7)}
            </span>

            <button
              className="group-hover:opacity-100 opacity-0"
              onClick={() => handleCopy(userIdentification)}
            >
              <FaCopy className="text-gray-400 cursor-pointer" />
            </button>
          </div>
        );
      },
      minSize: 150,
      enableResizing: false,
    },
    {
      accessorKey: "user_email",
      header: () => t("dashboard.usersList.item-01"),
      cell(props) {
        return (
          <div className="flex items-center gap-2 md:text-nowrap">
            <span
              className="shrink max-w-[300px] truncate"
              title={props.row.original.user_email}
            >
              {props.row.original.user_email}
            </span>

            {!props.row.original.user_is_active && (
              <div className="shrink-0 w-[86px] h-5 pl-2 pr-2.5 py-0.5 bg-amber-400/25 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
                <div className="text-center text-yellow-600 text-xs font-medium font-['Inter'] leading-none">
                  {t("dashboard.usersList.item-18")}
                </div>
              </div>
            )}
          </div>
        );
      },
      minSize: 330,
    },
    {
      accessorKey: "user_role",
      header: () => t("dashboard.usersList.item-03"),
      cell: (props) => {
        let content;
        const userIdentification = props.row.original.user_identification;

        if (isLoadingRole)
          content = <AiOutlineLoading className="h-6 w-6 animate-spin" />;

        if (isErrorRole)
          content = (
            <Button size="sm" color="light" onClick={() => refetchRole}>
              Reload
            </Button>
          );

        if (isSuccessRole)
          content = (
            <RoleOptionsPart
              data={{ role: props.row.original.user_role }}
              entity_identification={user.entityIdentification as string}
              user_identification={customer_identification}
              customerId={userIdentification}
              list_role={resRole.data.element}
              disabled={userEndedSubscription}
            />
          );

        return content;
      },
      enableSorting: false,
      minSize: 150,
    },
    {
      accessorKey: "user_type",
      header: () => t("dashboard.usersList.item-04"),
      cell: (props) => {
        let content;
        const userIdentification = props.row.original.user_identification;

        if (isLoadingType)
          content = <AiOutlineLoading className="h-6 w-6 animate-spin" />;

        if (isErrorType)
          content = (
            <Button size="sm" color="light" onClick={() => refetchType}>
              Reload
            </Button>
          );

        if (isSuccessType)
          content = (
            <TypeOptionsPart
              data={{ type: props.row.original.user_type }}
              entity_identification={user.entityIdentification as string}
              user_identification={customer_identification}
              customerId={userIdentification}
              list_type={resType.data.element}
              disabled={userEndedSubscription}
            />
          );

        return content;
      },
      enableSorting: false,
      minSize: 150,
    },
    {
      accessorKey: "user_creation_date_time",
      header: () => t("dashboard.usersList.item-05"),
      cell: (props) => (
        <span className="md:text-nowrap">
          {moment(props.row.original.user_creation_date_time)
            .utc()
            .format("MMMM D, YYYY | HH:mm")}
        </span>
      ),
      minSize: 200,
      enableResizing: false,
    },
    {
      accessorKey: "user_account_status",
      header: () => (
        <div className="text-center">{t("dashboard.usersList.item-02")}</div>
      ),
      cell: (props) => {
        if (props.row.original.user_account_status === "BANNED")
          return (
            <div className="flex items-end justify-center">
              <Tooltip content={t("dashboard.usersList.item-17")}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Ban">
                    <path
                      id="Icon"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.4766 14.8907C12.4958 15.5892 11.2959 16 10 16C6.68629 16 4 13.3137 4 10C4 8.70414 4.41081 7.50423 5.1093 6.52339L13.4766 14.8907ZM14.8908 13.4765L6.52354 5.1092C7.50434 4.41077 8.7042 4 10 4C13.3137 4 16 6.68629 16 10C16 11.2958 15.5892 12.4957 14.8908 13.4765Z"
                      fill="#F87171"
                    />
                  </g>
                </svg>
              </Tooltip>
            </div>
          );

        if (props.row.original.user_account_status === "OK")
          return (
            <center>
              <div className="pl-2 pr-2.5 py-0.5 bg-emerald-100 rounded-[10px] justify-center items-center gap-1.5 inline-flex">
                <div className="w-2 h-2 relative">
                  <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-emerald-400 rounded-full" />
                </div>
                <div className="text-center text-emerald-800 text-xs font-medium font-['Inter'] leading-none">
                  {t("dashboard.usersList.item-11")}
                </div>
              </div>
            </center>
          );
      },
      enableSorting: false,
      minSize: 100,
      maxSize: 100,
    },
    {
      id: "actions",
      cell: (props) => {
        const userIdentification = props.row.original.user_identification;
        if (userIdentification !== customer_identification)
          return (
            <button
              onClick={() =>
                navigate(`/control-panel/users/${userIdentification}`, {
                  state: { navigateType: type },
                })
              }
              className=""
            >
              <FaArrowRight />
            </button>
          );
        else
          return (
            <Link to={`/control-panel/profile`}>
              <FaArrowRight />
            </Link>
          );
      },
      maxSize: 50,
    },
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const calculateCurrentPage = useCallback(() => {
    const totalItems = pagination?.total;
    if (limit <= 0) {
      throw new Error("Limit must be a positive integer.");
    }

    if (offset < 0) {
      throw new Error("Offset cannot be negative.");
    }

    // Ensure we don't have an invalid offset exceeding total items
    let newOffset = Math.min(offset, totalItems - 1);

    // Calculate the current page based on the offset and limit
    const currentPage = Math.floor(newOffset / limit);

    return currentPage;
  }, [offset, pagination?.total, limit]);

  const handlePageChange = (pageNumber: number) => {
    setOffset(pageNumber * limit);
  };

  let content;

  if (isLoading)
    content = (
      <Table.Row>
        <Table.Cell colSpan={8} align="center">
          <AiOutlineLoading className="h-6 w-6 animate-spin" />
        </Table.Cell>
      </Table.Row>
    );

  if (isError)
    content = (
      <Table.Row>
        <Table.Cell colSpan={8} align="center">
          <Button color="info" onClick={() => refetch()}>
            Reload
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  if (isSuccess)
    content = (
      <>
        {table.getRowModel().rows.map((row, i) => {
          if (i < limit) {
            return (
              <Table.Row key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id} className="px-3 py-2.5">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          } else return null;
        })}
      </>
    );

  useEffect(() => {
    if (selectedRowIds?.size == 0 && selectAll.isSelected) {
      setSelectAll({ isSelected: false, selectPage: 0 });
    }
  }, [selectedRowIds]);
  return (
    <div className="overflow-x-auto min-h-[340px]">
      <Table
        striped
        className="dl-table mb-0 users-list md:table-auto table-fixed"
      >
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <React.Fragment key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight px-3 py-2.5"
                  style={{
                    width: `${header.getSize()}px`,
                  }}
                >
                  <React.Fragment>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </React.Fragment>
                </Table.HeadCell>
              ))}
            </React.Fragment>
          ))}
        </Table.Head>
        <Table.Body>{content}</Table.Body>
      </Table>

      {pagination && (
        <div className="flex justify-between items-center flex-wrap my-6 gap-6">
          <div>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {t("dashboard.usersList.key2")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {offset + 1}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key3")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {users?.length ? users?.length + offset : 0}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key4")}{" "}
            </span>
            <span className="text-gray-700 text-sm font-semibold font-['Figtree'] leading-tight">
              {pagination?.total ?? 0}
            </span>
            <span className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
              {" "}
              {t("dashboard.usersList.key5")}
            </span>
          </div>

          {isSuccess && (
            <Pagination
              pageCount={pagination?.total_pages}
              currentPage={calculateCurrentPage()}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UsersListComponent;
