export function checkKeydownDecimalValue(e: any) {
  if (
    e.ctrlKey ||
    e.metaKey ||
    ["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)
  ) {
    return;
  }

  // Prevent multiple decimal points
  if (e.key === "." && e.currentTarget.value.includes(".")) {
    e.preventDefault();
  }

  // Allow only numeric input and one decimal point
  if (!/^[0-9.]$/.test(e.key)) {
    e.preventDefault();
  }
}

export function onInputCheckDecimal(e: any) {
  e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, "");

  // Ensure there's only one decimal point
  const parts = e.currentTarget.value.split(".");
  if (parts.length > 2) {
    e.currentTarget.value = parts[0] + "." + parts.slice(1).join("");
  }
}

export function onPasteCheckDecimal(e: any) {
  e.preventDefault();
  const pastedText = e.clipboardData.getData("text");

  // Remove invalid characters and ensure only one decimal point
  const sanitized = pastedText.replace(/[^0-9.]/g, "");
  const parts = sanitized.split(".");
  const validValue =
    parts.length > 2 ? parts[0] + "." + parts.slice(1).join("") : sanitized;

  document.execCommand("insertText", false, validValue);
}
